@if (!(isLoggedIn$ | async)) {
  <header class="navbar sticky-top top-header">
    <div class="container-fluid justify-content-end g-0">
      <ul ngbNav class="d-flex">
        <li ngbNavItem class="top-nav">
          <a class="search-button bd-mid-green-colour" type="submit" [routerLink]="'/login'"> Login <i-bs [name]="iconNames.PersonFill" width="18px" height="18px"
          class="bd-green-colour"></i-bs></a>
        </li>
      </ul>
    </div>
  </header>
  <header class="navbar sticky-top navbar-expand-lg bg-body-secondary pb-0 main-header">
    <div class="container-fluid bg-body-secondary">
      <a class="navbar-brand" routerLink="/home" [inlineSVG]="'/assets/logo.svg'"></a>
    </div>
  </header>
}
