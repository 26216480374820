import { Injectable, inject } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { concatLatestFrom } from '@ngrx/operators'
import { of } from 'rxjs'
import { catchError, switchMap, mergeMap } from 'rxjs/operators'
import { BackEndService } from '../services/backend.service'
import { Store } from '@ngrx/store'
import { selectAnArticleById, selectArticlesState } from './article.reducer'
import { Article } from './article.model'
import { Dictionary } from '../shared/components/filtering-search-component-base/filtering-search-types'

@Injectable()
export class ArticlesEffects {
  private actions$ = inject(Actions)
  private articlesSearchService = inject(BackEndService)
  store = inject<Store<Article>>(Store)

  loadArticles$ = createEffect(() => this.actions$.pipe(
    ofType('[Articles] Get Articles', '[Articles] Update Search', '[Articles] Get Articles From Newsletter'),
    concatLatestFrom((action : any) => this.store.select(selectArticlesState)),
    switchMap(([action, state]) => {
      let searchArguments : Dictionary<any> = {
          ...state.search,
          area: action.area,
          window: {
            start: state.listing.present + 1,
            size: state.listing.window,
          }
      }

      return this.articlesSearchService.search(searchArguments)
        .pipe(
          switchMap((data: any) => {
            let actionsToDispatch: any[] = [{ type: '[Articles] Load', articles: data.articles, listing: data.listing, area: action.area, extraData: data.extras }, {type: '[Articles] Articles Loaded'}]
            return actionsToDispatch
          }),
          catchError(() => {
            return of({ type: '[Articles API] Articles Loaded Error' })
          })
        )
      })
    )
  )
  downloadArticlesRemote$ = createEffect(() => this.actions$.pipe(
    ofType('[Articles] Download'),
    concatLatestFrom((arg: any) => this.store.select(selectArticlesState)),
    switchMap(([arg, state]) => {
      let searchArguments : Dictionary<any> = {
          ...state.search,
          ...arg.action
      }

      return this.articlesSearchService.search(searchArguments)
        .pipe(
          switchMap((data: any) => {
            return of({type: '[Articles] Download in progress', downloadID: data.result.message})
          }),
          catchError(() => {
            return of({ type: '[Articles API] Articles Loaded Error' })
          })
        )
      })
    )
  )

  loadArticle$ = createEffect(() => this.actions$.pipe(
      ofType('[Articles] Get An Article'),
      concatLatestFrom((action : any) => [this.store.select(selectAnArticleById(action.args.id)), this.store.select(selectArticlesState) ] ),

      mergeMap(([action, article, state]) => {
        if (article !== undefined && ((article as Article).body || (article as Article).extras?.['body'])) {
          return of(
            { type: '[Articles] Download finished'},
            { type: '[Articles] Set Current Article', id: action.args.id },
            { type: '[Title] Set Title', article }
            ) // data already exists in store
        } else {
          return this.articlesSearchService.article(action.args.area, action.args.id, state.search).pipe(
            switchMap((data:any) => {
              let actionsToDispatch: any[] = [
                { type: '[Articles] Load An Article', article: data.articles[0], extraData: data.extras },
                { type: '[Articles] Article Loaded' },
                { type: '[Articles] Set Current Article', id: action.args.id },
                { type: '[Title] Set Title', article: data.articles[0] },
              ]
              if (data.menus) {
                actionsToDispatch.push({ type: '[Menus] Load MegaMenu Updates', menus: data.menus })
              }
              return actionsToDispatch
            }),
            catchError(() => {
              return of({ type: '[Articles API] Articles Loaded Error' })}
            ),
          )
        }
      }),
    )
  )
}