@if ((isLoggedIn$ | async)) {
<nav aria-label="breadcrumb" class="m-3">
    <breadcrumb-buttons></breadcrumb-buttons>
    <ol class="breadcrumb mb-0 flex-nowrap">
        @for (bc of breadcrumbs; track bc.name) {

        <li class="breadcrumb-item text-nowrap" [class.active]="bc.active" [class.text-truncate]="!bc.active">
            @if (bc.link) {
            <a [routerLink]="bc.link" [queryParams]="bc.queryParams" [innerHTML]="bc.name"></a>
            } @else {
            <span [innerHTML]="bc.name"></span>
            }
        </li>

        }
    </ol>
</nav>
}