import { Injectable, inject } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Router } from '@angular/router'

import { mergeMap, switchMap, exhaustMap } from 'rxjs/operators'
import { of } from 'rxjs'
import * as UserActions from './user.actions'
import { AuthService } from '../services/auth.service'
import { Store } from '@ngrx/store'
import { nextURL, selectUser } from './user.reducer'
import { BackEndService } from '../services/backend.service'
import { concatLatestFrom } from '@ngrx/operators'

@Injectable()
export class UserEffects {
  private actions$ = inject(Actions)
  private authService = inject(AuthService)
  private backendService = inject(BackEndService)
  private router = inject(Router)
  private store = inject(Store)


  loginByIPAddress$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.AuthActionTypes.LOGINBYIP),
        switchMap((action:any) => {
          return this.authService.loginByIPAddress().pipe(
            mergeMap( (response:any) => {
              if (response.loggedIn) {
                return of({ type: '[Auth] Login Complete', user: response.user})
              } else {
                return of({ type: '[Auth] Login Incomplete'})
              }
            })
          )
        })
    )
  })

  loginUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.AuthActionTypes.LOGIN),
        switchMap((action:any) => {
          return this.authService.login(action.email, action.password).pipe(
            mergeMap( (response:any) => {
              if (response.loggedIn) {
                return [
                  { type: '[Auth] Login Complete', user: response.user},
                  { type: '[Auth] Login Demo User', user: response.user}
                ]
              } else {
                return of({ type: '[Auth] Login Error', error: response.error})
              }
            })
          )
        })
    )
  })
  logoutUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.AuthActionTypes.LOGOUT),
      switchMap(() => {
        return this.authService.logout().pipe(
          exhaustMap( (response:any) => {
            return this.router.navigate(['/login'])
          })
        )
      })
    )},
    { dispatch: false }
  )

  logoutIPUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.AuthActionTypes.LOGOUTBYIP),
      switchMap(() => {
        return this.authService.logout().pipe(
          exhaustMap( (response:any) => {
            return this.router.navigate(['/login'], {
              state: {
                noIPAddressCheck: true
              }
            })
          })
        )
      })
    )},
    { dispatch: false }
  )

  redirectUser$ = createEffect( () => {
    return this.actions$.pipe(
      ofType(UserActions.AuthActionTypes.LOGIN_COMPLETE),
      concatLatestFrom((action : any) => this.store.select(nextURL)),
      exhaustMap(([action, url]) => {
        this.router.navigateByUrl(url ?? '/news')
        return of({ type: "[User] Route Set" })
      })
    )
  })

  userHasSeen$ = createEffect( () => {
    return this.actions$.pipe(
      ofType(UserActions.AuthActionTypes.UPDATEUSERHASSEEN),
      concatLatestFrom((action:any) => this.store.select(selectUser)),
      exhaustMap(([{label}, user]) => {
        if (user && user.impersonation) {
          return of({ type: '[User] Has Seen Updated'})
        } else {
          return this.backendService.updateHasSeen(label).pipe(
            mergeMap( (response:any) => {
              if (response.ok) {
                return of({ type: '[User] Has Seen Updated'})
              } else {
                return of({ type: '[User] Has Seen Error', error: response.error})
              }
            })
          )
        }
      })
    )
  })

  userHasUpdatedHomePageSections$ = createEffect( () => {
    return this.actions$.pipe(
      ofType(UserActions.AuthActionTypes.UPDATEUSERHOMEPAGESECTIONS),
      switchMap(({sections}) => {
        return this.backendService.updateHomePageSections(sections).pipe(
          mergeMap( (response:any) => {
            if (response.ok) {
              return of({ type: '[User] Has Seen Updated'})
            } else {
              return of({ type: '[User] Has Seen Error', error: response.error})
            }
          })
        )
      })
    )
  })
}
