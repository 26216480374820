import { SearchCheckboxOption, SearchDateRangeOption, SearchMultiAutoCompleteOption, SearchNumberRangeOption, SearchNumbersOption, SearchOptionGroup, SearchOptionType, SearchStringOption } from 'src/app/shared/components/filtering-search-component-base/filtering-search-types'
import { IconNamesEnum } from 'ngx-bootstrap-icons'
import { AllCaseNameAutocomplete, CaseIncludeType, CaseNameAutocomplete, CaseStatus, CourtName, DocumentTypes, FilingType, JudgeName, industryCheckboxWithID } from '../../shared/components/filtering-search-component-base/filtering-search-common-definitions'

export const documentSearchOptionGroups: SearchOptionGroup[] = [
    {
        groupName: "Document",
        visible: true,
        colour: '#136481',
        options: [
            {
                icon: IconNamesEnum.Calendar4Range,
                id: 'content',
                type: SearchOptionType.stringinput,
                name: 'Content',
                lastResort: true,
                searchString: '',
                matchingOption: 'phrase',
                matchingOptionTypes: {
                    exactPhrase: true,
                    allWords: true,
                    someWords: true,
                    booleanMode: true,
                },
                checkboxes: [
                    {name: "Description", value: 'description', checked: true},
                    {name: "Content", value: 'content', checked: true},
                ]
            } as SearchStringOption,
            {
                icon: IconNamesEnum.Calendar4Range,
                id: 'date',
                type: SearchOptionType.daterange,
                name: 'Date',
                minDate: new Date(),
                maxDate: new Date(),
            } as SearchDateRangeOption,
            DocumentTypes,
            {
                ...industryCheckboxWithID,
                id: 'dominantindustry',
                name: 'Industries',
            } as SearchCheckboxOption,
        ]
    },
    {
        groupName: "Bankruptcy Case",
        visible: true,
        colour: '#9BCFB2',
        options: [
            AllCaseNameAutocomplete,
            CaseIncludeType,
            FilingType,
            CaseStatus,
            CourtName,
            JudgeName,
        ]
    }
]