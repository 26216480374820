@if (validatingToken ) {
<div class="d-flex justify-content-center mt-5">
    <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
</div>
} @else {

    <div class="container">
        <div class="row">
            <div class="col message">
                <h2>The provided token is not valid – please obtain another one.</h2>
            </div>
        </div>
    </div>

}

