<div class="radio-wrapper">
    <input type="radio" name="event" class="yes" [(ngModel)]="value" (ngModelChange)="change($event)" id="radio-yes" value="light">

    <label for="radio-yes"><i-bs [name]="iconNames.SunFill" class="bi me-2" width="16" height="16"></i-bs>
    </label>

    <input type="radio" name="event" class="neutral" [(ngModel)]="value" (ngModelChange)="change($event)" id="radio-neutral" value="auto">
    <label for="radio-neutral">
        <i-bs [name]="iconNames.CircleHalf" class="bi me-2" width="16" height="16"></i-bs>
    </label>

    <input type="radio" name="event" class="no" [(ngModel)]="value" (ngModelChange)="change($event)" id="radio-no" value="dark">
    <label for="radio-no">
        <i-bs [name]="iconNames.MoonFill" class="bi me-2" width="16" height="16"></i-bs>
    </label>
</div>
