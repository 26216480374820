import { Component, inject } from '@angular/core'
import { Store } from '@ngrx/store'
import { loginByIPAddress, loginUser } from '../../state/user.actions'
import { UserState } from 'src/app/state/user.reducer'
import { Observable } from 'rxjs'
import { selectUserState } from 'src/app/state/user.selectors'
import { AuthService } from 'src/app/services/auth.service'
import { Router } from '@angular/router'
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap'
import { NgTemplateOutlet, AsyncPipe } from '@angular/common'
import { FormsModule } from '@angular/forms'

@Component({
    selector: 'front-end-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: true,
    imports: [FormsModule, NgbAlert, NgTemplateOutlet, AsyncPipe]
})
export class LoginComponent  {
  private store = inject<Store<UserState>>(Store)
  private authService = inject(AuthService)
  private router = inject(Router)


  public pageMode = 'login'

  public userEmail      = ''
  public userPassword   = ''
  public userState$: Observable<UserState>

  public userFullname = ''
  public userTelephone = ''
  public userOrganization = ''

  public message = ''
  public trialMessage = ''

  public isLoading = false
  public isCheckingIPAddress = true

  constructor() {
    this.userState$ = this.store.select(selectUserState) as unknown as Observable<UserState>

    if ((this.router.getCurrentNavigation()?.extras.state?.['noIPAddressCheck'] ?? false) !== true) {
      this.store.dispatch(loginByIPAddress())
    }
  }

  onSubmit() {
    this.store.dispatch(loginUser({
      email: this.userEmail,
      password: this.userPassword
    }))
  }

  goBackToLogin(event : Event) {
    event.preventDefault()
    this.pageMode = 'login'
  }

  forgottenPassword(event : Event) {
    event.preventDefault()
    this.pageMode = 'forgottenPassword'
  }

  onForgottenPasswordSubmit() {
    this.isLoading = true
    this.authService.forgottenPassword(this.userEmail).subscribe( (response:any) => {
      this.isLoading = false
      this.pageMode = 'messaging'
        this.message = `If we have found a login for the email address <strong>${this.userEmail}</strong>, an email has been sent with subject "Forgotten password link inside"`
      })
  }

  applyForTrial(event : Event) {
    event.preventDefault()
    this.pageMode = 'applyForTrial'
  }

  onApplyForTrial() {
    this.isLoading = true
    this.authService.requestTrial({
      userEmail: this.userEmail,
      userFullname: this.userFullname,
      userTelephone: this.userTelephone,
      userOrganization: this.userOrganization,
    }).subscribe((response:any) => {
      this.isLoading = false
      if (response.ok) {
        this.pageMode = 'messaging'
        this.message = `We have received your request for a trial and someone will be in contact with you within one working day.`
      } else {
        this.trialMessage = response.message
      }
    })
  }
}
