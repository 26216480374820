import { Component, OnInit, inject } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { AuthService } from 'src/app/services/auth.service'

@Component({
    selector: 'app-legacy-login',
    templateUrl: './legacy-login.component.html',
    styleUrls: ['../forgotten-password/forgotten-password.component.scss'],
    standalone: true
})
export class LegacyLoginComponent implements OnInit {
  private router = inject(Router)
  private authService = inject(AuthService)


  public jwt: string
  public validatingToken: boolean = true

  constructor() {
    const route = inject(ActivatedRoute)

    this.jwt = route.snapshot.paramMap.get('jwt') ?? ''
  }

  ngOnInit(): void {
    this.authService.validateJWT(this.jwt).subscribe((response: any) => {
      if (response.loggedIn) {
        this.router.navigateByUrl('/home')
      } else
        this.validatingToken = false
    })
  }

}
