<app-public-page-header></app-public-page-header>

<ng-scrollbar class="ngr-scrollbar"  visibility="visible">
    @if (tokenValid) {

    @if (validatingToken) {
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="m-4 progress-container">
                    <ngb-progressbar [value]="100" [striped]="true" [animated]="true"></ngb-progressbar>
                </div>
            </div>
        </div>
    </div>
    } @else {

    @if (tokenOK) {
    <div class="container">
        <div class="row">
            <div class="col message">
                <div class="card mt-2">
                    @if (passwordChanging) {
                    <div class="card-overlay">
                        <div class="spinner-border">

                        </div>
                    </div>
                    }
                    @if (askingForPassword) {
                        <div class="card-header">
                            Change Password
                        </div>
                        <div class="card-body">
                            <div class="mb-3">
                                <label for="newPassword1" class="form-label">New Password</label>
                                <input type="password" class="form-control" id="newPassword1" [(ngModel)]="newPassword">
                                <app-password-strength [passwordToCheck]="newPassword" (passwordStrength)="onPasswordStrengthChanged($event)"></app-password-strength>
                            </div>
                            <div class="mb-3">
                            </div>
                            <button type="submit" class="btn btn-gradient" (click)="changePassword()" [disabled]="!strongPassword">Submit</button>
                        </div>
                    } @else {
                        @if (passwordChangedOK) {
                            <div class="card-header">
                                Password Changed
                            </div>
                            <div class="card-body">
                                <div class="mb-3">
                                    <p>Your password has been changed</p>
                                </div>
                            </div>
                        } @else {
                            <div class="card-header">
                                Could Not Change Password
                            </div>
                            <div class="card-body">
                                <div class="mb-3">
                                    <p>Please try again</p>
                                </div>
                            </div>
                        }
                    }

                </div>
            </div>
        </div>
    </div>
    } @else {

    <div class="container">
        <div class="row">
            <div class="col message">
                <h2>The provided token is not valid – please obtain another one.</h2>
            </div>
        </div>
    </div>

    }
    }
    } @else {
    <div class="container">
        <div class="row">
            <div class="col message">
                <h2>The provided token is not valid – please obtain another one.</h2>
            </div>
        </div>
    </div>
    }
</ng-scrollbar>