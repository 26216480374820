import { Component, OnInit, inject } from '@angular/core'
import { Store } from '@ngrx/store'
import { NgbProgressbar } from '@ng-bootstrap/ng-bootstrap'
import { NgScrollbar } from 'ngx-scrollbar'
import { User } from 'src/app/state/user.model'
import { Observable } from 'rxjs'
import { ActivatedRoute, Router } from '@angular/router'
import { AsyncPipe } from '@angular/common'
import { CookieService } from 'ngx-cookie-service'
import { selectUser } from 'src/app/state/user.reducer'
import { PublicPageHeaderComponent } from 'src/app/shared/components/public-page-header/public-page-header.component'

@Component({
  selector: 'app-impersonate-user',
  standalone: true,
  imports: [PublicPageHeaderComponent, NgbProgressbar, NgScrollbar, AsyncPipe],
  templateUrl: './impersonate-user.component.html',
  styleUrl: './impersonate-user.component.scss'
})
export class ImpersonateUserComponent implements OnInit {

  public urlValid = false
  public checkingImpersonationToken = false
  public message = ''
  public user$: Observable<User | null>
  public token = ''

  private store = inject(Store<any>)
  private route = inject(ActivatedRoute)
  private router = inject(Router)
  private cookieService = inject(CookieService)

  constructor() {
    this.user$ = this.store.select(selectUser)
    this.token = this.route.snapshot.params['token']
  }

  public ngOnInit(): void {
    this.user$.subscribe((user: User | null) => {
      if (user === null) {
        this.cookieService.set('token', this.token, {
          secure: false,
          path: '/'
        })
        //this.store.dispatch(impersonateUser())
        this.router.navigateByUrl('/my-account')
      }
    })
  }
}
