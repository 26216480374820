import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { InjectionToken, makeEnvironmentProviders, ENVIRONMENT_INITIALIZER, PLATFORM_ID } from '@angular/core';
function clarityScript(projectId) {
  return `(function(c,l,a,r,i,t,y){
    c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
    y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
  })(window, document, "clarity", "script", "${projectId}");`;
}
const NGX_CLARITY_CONFIG_TOKEN = new InjectionToken('ngx-clarity.config');
/**
 * Builds configured providers for injecting Microsoft Clarity scripts during
 * application initialization.
 * @param config The runtime configuration for the Clarity providers.
 * @returns the Clarity providers.
 */
function provideClarity(config) {
  return makeEnvironmentProviders([{
    provide: NGX_CLARITY_CONFIG_TOKEN,
    useValue: config
  }, {
    provide: ENVIRONMENT_INITIALIZER,
    useFactory: (platformId, d, {
      enabled,
      projectId
    }) => {
      return () => {
        if (isPlatformBrowser(platformId) && enabled) {
          const s = d.createElement('script');
          s.type = 'text/javascript';
          s.innerHTML = clarityScript(projectId);
          d.head.appendChild(s);
        }
      };
    },
    deps: [PLATFORM_ID, DOCUMENT, NGX_CLARITY_CONFIG_TOKEN],
    multi: true
  }]);
}

/**
 * Generated bundle index. Do not edit.
 */

export { provideClarity };
