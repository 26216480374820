import { SearchMultiAutoCompleteOption, SearchOptionGroup } from 'src/app/shared/components/filtering-search-component-base/filtering-search-types'
import { industryCheckbox, MarketTickers, organisationName, SECDocumentSearchGroup, SicSearch } from '../../shared/components/filtering-search-component-base/filtering-search-common-definitions'

export const secDocumentSearchOptionGroups: SearchOptionGroup[] = [
    SECDocumentSearchGroup,
    {
        groupName: "Entity",
        visible: true,
        colour: '#9BCFB2',
        options: [
            {
                ...organisationName,
                lastResort: true,
                searchServiceParameter: {
                    area: 'organizations/autocomplete',
                    parameters: {
                        name: {
                            name: "SECOrganization",
                            type: "stringinput",
                            format: 'autocomplete',
                            returndata: 'brief',
                        }
                    },
                    window: {
                        start: 1,
                        size: 5,
                    }
                },
            } as SearchMultiAutoCompleteOption,
            {
                ...industryCheckbox,
                id: 'entity.dominant_industry',
                name: 'Industries',
            },
            {
                ...SicSearch,
                id: 'entity.sic',
            },
            MarketTickers,
        ]
    }
]