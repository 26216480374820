@for (toast of toastService.toasts; track toast) {
  

    @if (toast.header) {
<ngb-toast
        [class]="toast.classname"
        [autohide]="toast.autohide ?? true"
        [delay]="toast.delay || 5000"
        (hidden)="toastService.remove(toast)"
    >
        <ng-template ngbToastHeader>
            <div class="me-auto" [innerHTML]="toast.header">
            </div>
        </ng-template>
        <span [innerHTML]="toast.textOrTpl| safeHtml"></span>
    </ngb-toast>
} @else {

        <ngb-toast
        [class]="toast.classname"
        [autohide]="toast.autohide ?? true"
        [delay]="toast.delay || 5000"
        (hidden)="toastService.remove(toast)"
        >
        <span [innerHTML]="toast.textOrTpl| safeHtml"></span>
        </ngb-toast>
    
}

    

}
