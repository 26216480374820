<section class="h-100 gradient-form" style="background-color: #eee;">
  <div class="container py-5 h-100">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col-xl-10">
        <div class="card rounded-3 text-black">
          <div class="row g-0">
            <div class="col-lg-6">
              <div class="card-body p-md-5 mx-md-4">

                <div class="text-center">
                  <img class="img-fluid" src="/assets/logo.svg">
                </div>

                @if (pageMode == 'login') {

                  @if (userState$ | async; as userState) {

                    <form (ngSubmit)="onSubmit()" ngNativeValidate class="needs-validation">
                      @if (! userState.hasError) {
                        <p>Please login to your account</p>
                      }
                      @if (userState.hasError) {
                        <div class="alert alert-warning was-validated">{{ userState.statusMessage }}</div>
                      }

                      <div class="form-floating mb-4">
                        <input [(ngModel)]="userEmail" name="email" type="email" required class="form-control" id="email" placeholder="enter your email">
                        <label class="form-label" for="email">Email address</label>
                      </div>

                      <div class="form-floating mb-4">
                        <input [(ngModel)]="userPassword" name="password" type="password" required class="form-control" id="password" placeholder="enter a password">
                        <label class="form-label" for="password">Password</label>
                      </div>

                      <div class="text-center pt-1 mb-5 pb-1">
                        <div class="d-grid gap-2">
                          @if (!userState.isLoading) {
                            <button class="btn btn-bankruptcydata btn-block mb-3" type="submit">
                              Login
                            </button>
                          } @else {

                            <button class="btn btn-bankruptcydata btn-block mb-3" type="button" disabled>
                              <span class="spinner-border spinner-border-sm" role="status"></span>
                            </button>

                          }
                        </div>
                        <a (click)="forgottenPassword($event)" class="text-muted" href="#!">Forgot your password?</a>
                      </div>

                      <div class="d-flex align-items-center justify-content-center pb-4">
                        <p class="mb-0 me-2">Don't have an account?</p>
                        <button type="button" class="btn btn-blue-bankruptcydata" (click)="applyForTrial($event)">Apply for a trial</button>
                      </div>
                    </form>

                  }

                }
                @if (pageMode == 'forgottenPassword') {
                  <form (ngSubmit)="onForgottenPasswordSubmit()" ngNativeValidate class="needs-validation">

                    <div class="form-floating mb-4">
                      <input [(ngModel)]="userEmail" name="email" type="email" required class="form-control" id="email" placeholder="enter your email">
                      <label class="form-label" for="email">Email address</label>
                    </div>

                    <div class="text-center pt-1 mb-5 pb-1">
                      <div class="d-grid gap-2">
                        @if (!isLoading) {
                          <button class="btn btn-bankruptcydata btn-block mb-3" type="submit">
                            Request login link
                          </button>
                        } @else {

                          <button class="btn btn-bankruptcydata btn-block mb-3" type="button" disabled>
                            <span class="spinner-border spinner-border-sm" role="status"></span>
                          </button>

                        }
                      </div>
                      <a (click)="goBackToLogin($event)" class="text-muted" href="#!">Go back to login</a>
                    </div>
                  </form>
                }
                @if (pageMode == 'applyForTrial') {
                  <form (ngSubmit)="onApplyForTrial()" ngNativeValidate class="needs-validation">

                    <div class="form-floating mb-4">
                      <input [(ngModel)]="userFullname" name="fullname" type="text" required class="form-control" id="fullname" placeholder="Full name...">
                      <label class="form-label" for="fullname">Full name</label>
                    </div>

                    <div class="form-floating mb-4">
                      <input [(ngModel)]="userEmail" name="email" type="email" required class="form-control" id="email" placeholder="Work email">
                      <label class="form-label" for="email">Work email address</label>
                    </div>
                    @if (trialMessage.length) {
                      <ngb-alert type="danger" [dismissible]="false">You must provide a <strong>work email address</strong></ngb-alert>
                    }

                    <div class="form-floating mb-4">
                      <input [(ngModel)]="userTelephone" name="email" type="tel" required class="form-control" id="email" placeholder="Telephone number">
                      <label class="form-label" for="email">Telephone number</label>
                    </div>

                    <div class="form-floating mb-4">
                      <input [(ngModel)]="userOrganization" name="email" type="text" required class="form-control" id="email" placeholder="Organization name">
                      <label class="form-label" for="email">Organization name</label>
                    </div>

                    <div class="text-center pt-1 mb-5 pb-1">
                      <div class="d-grid gap-2">
                        @if (isLoading) {
                          <ng-container *ngTemplateOutlet="showLoading"></ng-container>
                        } @else {
                          <button class="btn btn-bankruptcydata btn-block mb-3" type="submit">
                            Apply for trial
                          </button>
                        }
                      </div>
                      <a (click)="goBackToLogin($event)" class="text-muted" href="#!">Go back to login</a>
                    </div>
                  </form>
                }
                @if (pageMode == 'messaging') {

                  <div [innerHTML]="message"></div>
                  <a (click)="goBackToLogin($event)" class="text-muted" href="#!">Go back to login</a>

                }
              </div>
            </div>
            <div class="col-lg-6 d-flex align-items-center gradient-custom-2">
              <div class="text-white px-3 py-4 p-md-5 mx-md-4">
                <h4 class="mb-4">THE DATABASE OF RECORD FOR CORPORATE BANKRUPTCY AND RESTRUCTURING INFORMATION</h4>
                <p class="small mb-0">Our platform allows subscribers to flesh out extensive details pertaining to individual bankruptcies, identify a group of companies that meet specific criteria, or stay on top of the industry as a
                whole. </p>

                <p class="small mb-0">BankruptcyData has revolutionized the way in which professionals interested in the bankruptcy sector consume, share, manage, and leverage vital data, research, and analysis. We provide a
                  competitive advantage to those interested in making more informed decisions regarding the bankruptcy and distressed sectors
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<ng-template #showLoading>
  <button class="btn btn-bankruptcydata btn-block mb-3" type="button" disabled>
    <span class="spinner-border spinner-border-sm" role="status"></span>
  </button>
</ng-template>
