import { Component, EventEmitter, Input, Output } from '@angular/core'
import { IconNamesEnum, NgxBootstrapIconsModule } from 'ngx-bootstrap-icons'
import { FormsModule } from '@angular/forms'

@Component({
    selector: 'three-position-switch',
    templateUrl: './three-position-switch.component.html',
    styleUrls: ['./three-position-switch.component.scss'],
    standalone: true,
    imports: [FormsModule, NgxBootstrapIconsModule]
})
export class ThreePositionSwitchComponent {

  @Input() public value: string = 'light'
  @Output() newvalue = new EventEmitter<string>()

  public iconNames = IconNamesEnum

  change(value: string) {
    this.newvalue.emit(value)
  }
}