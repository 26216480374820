import { Component, HostListener, OnInit, TemplateRef, inject } from '@angular/core'
import { Router, NavigationStart, ActivationEnd, NavigationEnd, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router'
import {
  trigger,
  style,
  animate,
  transition,
  query,
  sequence,
  stagger,
  // ...
} from '@angular/animations'

import { Store } from '@ngrx/store'
import { Observable, Subscription, map } from 'rxjs'
import { IconNamesEnum, NgxBootstrapIconsModule } from 'ngx-bootstrap-icons'
import { MenuDefinition, selectAllMenus, Article, selectStatusState } from './state'
import { isLoggedIn, selectUser } from './state/user.reducer'
import { User } from './state/user.model'
import { SavedSearchService } from './services/saved-search.service'
import { openClose } from './shared/animations/open-close-animations'
import { ToastService } from './services/toast.service'
import { debounce } from 'typescript-debounce-decorator'
import { Situation } from './pages/situations'
import { Organization } from './pages/organizations'
import { Person } from './pages/people'
import { NewsArticle } from './pages/news'
import { BackEndService } from './services/backend.service'
import { MixpanelService } from './services/mixpanel.service'
import { NgbOffcanvas, NgbNav, NgbPopover, NgbNavItem, NgbNavItemRole, NgbCollapse, NgbNavModule } from '@ng-bootstrap/ng-bootstrap'
import { ThemeService } from './services/theme.service'
import { SavedSearchesComponent } from './shared/components/saved-searches/saved-searches.component'
import { ToastsContainer } from './shared/components/toasts-container/toasts-container.component'
import { BreadcrumbsComponent } from './shared/components/breadcrumbs/breadcrumbs.component'
import { InlineSVGModule } from 'ng-inline-svg-2'
import { ThreePositionSwitchComponent } from './shared/components/three-position-switch/three-position-switch.component'
import { NgTemplateOutlet, NgClass, AsyncPipe, DatePipe, KeyValuePipe } from '@angular/common'
import { MasterRouterLinkDirective } from '../../projects/lib-master-detail/src/lib/directives/master-router-link.directive'
import { A11yModule } from '@angular/cdk/a11y'
import { FormsModule } from '@angular/forms'
import * as Sentry from "@sentry/angular"
import { SiteSearchService } from './services/site-search.service'
import { ShepherdService } from 'angular-shepherd'
import { offset } from '@floating-ui/dom'
import { updateUserHasSeen } from './state/user.actions'
import { FeatueFlagsService } from './services/featue-flags.service'
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    openClose,
    trigger('megaMenuTrigger', [
      transition(':enter', [
        style({ height: 0, overflow: "hidden" }),
        query(".menu-item", [
          style({ opacity: 0, transform: "translateY(-50px)" })
        ]),
        sequence([
          animate("200ms ease-in", style({ height: "*" })),
          query(".menu-item", [
            stagger(50, [
              animate("100ms ease", style({ opacity: 1, transform: "none" }))
            ])
          ])
        ])
      ]),
      transition(':leave', [
        style({ height: '*', overflow: "hidden", 'z-index': 5 }),
        query(".menu-item", [style({ opacity: 1, transform: "none" })]),
        sequence([
          animate("50ms", style({ height: 0 }))
        ])
      ])
    ]),
  ],
  standalone: true,
  imports: [
    NgbNav,
    NgxBootstrapIconsModule,
    FormsModule,
    A11yModule,
    NgbPopover,
    MasterRouterLinkDirective,
    NgTemplateOutlet,
    ThreePositionSwitchComponent,
    NgbNavItem,
    NgbNavItemRole,
    RouterLink,
    InlineSVGModule,
    NgbCollapse,
    NgbNavModule,
    RouterLinkActive,
    BreadcrumbsComponent,
    ToastsContainer,
    SavedSearchesComponent,
    RouterOutlet,
    NgClass,
    AsyncPipe,
    DatePipe,
    KeyValuePipe]
})


export class AppComponent implements OnInit {
  store = inject<Store<any>>(Store)
  private router = inject(Router)
  private savedSearchService = inject(SavedSearchService)
  private _mixpanel = inject(MixpanelService)
  private toastService = inject(ToastService)
  searchService = inject(BackEndService)
  private offcanvasService = inject(NgbOffcanvas)
  public themeService = inject(ThemeService)
  private siteSearchService = inject(SiteSearchService)
  private shepherdService = inject(ShepherdService)
  private featureFlagsService = inject(FeatueFlagsService)

  public readonly searchMegaMenuIndex = 10
  public iconNames = IconNamesEnum

  public title = 'front-end'
  public navbarCollapsed: boolean = true
  public activeId = ''

  public menus = []
  public menus$: Observable<MenuDefinition[]>
  public isLoggedIn$: Observable<boolean>
  public user$: Observable<User | null>

  public newsSearchResults$ = new Observable<NewsArticle[]>
  public situationSearchResults$ = new Observable<Situation[]>
  public organisationSearchResults$ = new Observable<Organization[]>
  public personSearchResults$ = new Observable<Person[]>
  public courtDocumentSearchResults$ = new Observable<any[]>
  public secDocumentSearchResults$ = new Observable<any[]>

  public user: User = {
    Email: '',
    UserName: '',
    ContactFirst: '',
    ContactLast: '',
    CustId: '0',
    Company: 'Not logged in',
    searches: {},
    hasSeen: {},
    nextGenCSO: undefined,
    downloads: false,
    downloadLimit: 0,
    ipuser: false,
    internal: false,
    impersonation: false,
  }
  public routeChangeSub$: Subscription = Subscription.EMPTY

  public activeBaseURL = ''
  public megaMenuEntryTimer!: any
  public megaMenuActiveIndex = -1
  public previousMegaMenuActiveIndex = -1


  public contentSet = ''

  public searchNameToDelete = ''

  public searchStringMinLength = 2
  public searchString = ''
  public recentSearches: string[] = []

  public feedbackForm = {
    reason: '',
    message: ''
  }
  public feedbackFormState = 'ready'

  public commitTimeStamp: string = (window as any)['env'].commit_timestamp

  constructor() {
    const _mixpanel = this._mixpanel;

    this.menus$ = this.store.select(selectAllMenus)
    this.isLoggedIn$ = this.store.select(isLoggedIn)
    this.user$ = this.store.select(selectUser)

    _mixpanel.init()
  }

  async ngOnInit() {
    try {
      this.user$.subscribe((user) => {
        if (user !== null) {

          this._mixpanel.identify(user.CustId)
          this._mixpanel.addGroup('company', user.Company)
          this.user = user
          Sentry.setUser({
            id: user.CustId,
            email: user.Email,
            company: user.Company
          })

          if (!window.AdobeDC) {
            this._mixpanel.track('AdobeDC not loaded')
          }

          if (!user?.ipuser && !user?.hasSeen['calendarMenu']) {
            let loadingStarted = false
            const status$ = this.store.select(selectStatusState).subscribe((status) => {
              if (this.router.url !== '/home' && this.router.url !== '/data/calendar') {
                if (status.loading) {
                  loadingStarted = true
                } else if (loadingStarted) {
                  setTimeout(() => {
                    this.shepherdService.defaultStepOptions = {}
                    this.shepherdService.modal = false
                    this.shepherdService.confirmCancel = false
                    this.shepherdService.addSteps([
                      {
                        attachTo: {
                          element: '#nav-2',
                          on: 'bottom'
                        },
                        canClickTarget: false,
                        highlightClass: 'throbber',
                        id: 'calendar-search',
                        cancelIcon: {
                          enabled: true
                        },
                        title: 'New Feature',
                        text: 'Check out our <a href="/data/calendar">new court calendar</a>.',
                        floatingUIOptions: {
                          middleware: [offset({ mainAxis: 16, crossAxis: 16 })]
                        },
                      },
                    ])
                    this.shepherdService.start()
                    setTimeout(() => {
                      this.shepherdService.next()
                      status$.unsubscribe()
                    }, 8000)
                    this.store.dispatch(updateUserHasSeen({ label: 'calendarMenu' }))

                  }, 1500
                  )
                }
              }
            })
          }
        } else {
          Sentry.setUser(null)
        }
      })
    }
    catch (ex) {
      throw new Error(`Script load exception=${ex}`)
    }
    this.routeChangeSub$ = this.router.events.subscribe((event): void => {
      if (event instanceof NavigationEnd) {
        this.activeBaseURL = event.url.split('?')[0].split('/')[1]
        this._mixpanel.trackPageView({
        })
      } else if (event instanceof NavigationStart) {
        const nextActiveBaseURL = event.url.split('?')[0].split('/')[1]
        if (this.activeBaseURL !== nextActiveBaseURL) {
          // this.store.dispatch(resetArticleState())
        }
        this.activeBaseURL = nextActiveBaseURL
        this.closeMegaMenus()
      } else if (event instanceof ActivationEnd) {
        this.contentSet = event.snapshot.data['contentSet']
      }
    })

    this.siteSearchService.searchToShow$.subscribe(search => {
      this.onMegaMenuClick(10)
      this.searchString = search
      this.doSearch()
    })
  }

  closeMegaMenus(mouseEnter = false) {
    if (mouseEnter && this.megaMenuActiveIndex === 10) {
      return
    }
    this.megaMenuActiveIndex = -1
    this.previousMegaMenuActiveIndex = -1
    this.searchString = ''
  }

  onMegaMenuEnter(index: number) {
    this.megaMenuEntryTimer = setTimeout(
      () => {
        this.previousMegaMenuActiveIndex = this.megaMenuActiveIndex
        this.megaMenuActiveIndex = index
      }, 250)
  }
  onMegaMenuLeave() {
    clearTimeout(this.megaMenuEntryTimer)
  }

  onMegaMenuClick(index: number) {
    if (this.megaMenuActiveIndex === -1) {
      this.megaMenuActiveIndex = index
    } else {
      if (this.megaMenuActiveIndex === index) {
        this.megaMenuActiveIndex = -1
        this.previousMegaMenuActiveIndex = -1
      } else {
        this.previousMegaMenuActiveIndex = this.megaMenuActiveIndex
        this.megaMenuActiveIndex = index
      }
    }

    if (index === this.searchMegaMenuIndex) {
      try {
        this.recentSearches = JSON.parse(localStorage.getItem('recentSearches') ?? '[]')
      } catch (e) {
        this.recentSearches = []
      }
    }
  }

  isMegaMenu(menu: MenuDefinition): boolean {
    return menu.columns !== undefined && Object.keys(menu.columns).length > 0
  }

  numberOfMegaMenuColumns(menu: MenuDefinition): number {
    return Object.keys(menu.columns ?? {}).length
  }

  hideColumnByFeatureFlag(area: string, key: string) : boolean {
    return (key !== 'documentssec' || this.featureFlagsService.hasFeatureFlag('documentssec'))
  }

  hideChildByFeatureFlag(): boolean {
    return true
  }

  numberOfResultsToShow(articles: Article[]) {
    return articles.filter(a => a.id > 0).length
  }


  gotoSearch(search: any) {
    this.closeMegaMenus()
    this.navbarCollapsed = true
    if (search.parameters.id) {
      if (search.parameters.embedded) {
        this.router.navigateByUrl('/' + search.parameters.area + '/' + search.parameters.id + '#99', {
          state: search.parameters
        })
      } else {
        this.router.navigateByUrl('/' + search.parameters.area + '/' + search.parameters.id)
      }

    } else {
      // There is a bug in saved searches from documents
      let state = structuredClone(search.parameters)
      if (!state.parameters) {
        state.parameters = structuredClone(state)
        state.area = state.area ?? 'documents'
      }

      if (this.router.url.substring(1) === search.parameters.area) {
        this.savedSearchService.loadSearch(state)
      } else {
        this.router.navigateByUrl('/' + (state.parameters.area ?? state.area), {
          state
        })
      }
    }
  }

  editSavedSearch(search: any) {
    this.closeMegaMenus()
    this.savedSearchService.editSearch(search)
  }

  deleteSavedSearch(search: any) {
    this.closeMegaMenus()
    this.savedSearchService.deleteSearch(search)
  }


  setTheme(theme: string, toastMessage = false) {
    if (toastMessage) {
      this.toastService.show(`${theme.charAt(0).toUpperCase() + theme.slice(1)} mode set`, {
        classname: 'bg-success',
        autohide: true,
        delay: 5000,
      })
    }
    this.themeService.setTheme(theme)
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    this.closeMegaMenus()
  }

  @debounce(750)
  doSearch() {
    if (this.searchString.length >= this.searchStringMinLength) {

      if (this.recentSearches[0]?.length < this.searchString.length &&
        this.searchString.toLowerCase().indexOf(this.recentSearches[0]?.toLowerCase()) === 0) {
        this.recentSearches[0] = this.searchString
      } else {
        this.recentSearches.unshift(this.searchString)
        this.recentSearches = [...new Set(this.recentSearches)].slice(0, 5)
      }
      localStorage.setItem('recentSearches', JSON.stringify(this.recentSearches))

      this.newsSearchResults$ = this.searchService.search(
        {
          parameters: {
            content: {
              name: "Content",
              type: "stringinput",
              value: this.searchString,
              match: "all",
              snippets: 1,
            }
          },
          area: "news",
          window: {
            start: 1,
            size: 5
          },
        }
      ).pipe(map((result: any) => result.articles))

      this.situationSearchResults$ = this.searchService.search(
        {
          parameters: {
            name: {
              name: "name",
              format: "autocomplete",
              returndata: "brief",
              type: "stringinput",
              value: this.searchString,
            }
          },
          area: "situations/autocomplete",
          window: {
            start: 1,
            size: 10
          },
        }
      ).pipe(map((result: any) => result.articles))

      this.organisationSearchResults$ = this.searchService.search(
        {
          parameters: {
            name: {
              name: "name",
              format: "autocomplete",
              returndata: "brief",
              type: "stringinput",
              value: this.searchString,
            }
          },
          area: "organizations/autocomplete",
          window: {
            start: 1,
            size: 5
          },
        }
      ).pipe(map((result: any) => result.articles))

      this.personSearchResults$ = this.searchService.search(
        {
          parameters: {
            name: {
              name: "name",
              format: "autocomplete",
              returndata: "brief",
              type: "stringinput",
              value: this.searchString,
            }
          },
          area: "people/autocomplete",
          window: {
            start: 1,
            size: 5
          },
        }
      ).pipe(map((result: any) => result.articles))

      this.courtDocumentSearchResults$ = this.searchService.search(
        {
          parameters: {
            content: {
              name: "Content",
              type: "stringinput",
              value: this.searchString,
              match: "phrase",
              filter: [
                "description",
                "content"
              ],
              snippets: 1
            },
            case_include_type: {
              name: "Add Non-Priority",
              type: "checkboxes",
              value: ["non-priority"]
            },

          },
          area: "documents",
          window: {
            start: 1,
            size: 5
          },
        }
      ).pipe(map((result: any) => result.articles))
      this.secDocumentSearchResults$ = this.searchService.search(
        {
          parameters: {
            content: {
              name: "Content",
              type: "stringinput",
              value: this.searchString,
              match: "phrase",
              filter: [
                "description",
                "content"
              ],
              snippets: 1
            },
          },
          area: "secdocuments",
          order: {
            "column": "date",
            "direction": "desc"
          },
          window: {
            start: 1,
            size: 5
          },
        }
      ).pipe(map((result: any) => result.articles))

    }
  }

  getSearchesForArea(area: string) {
    if (area === 'documents') {
      return (this.user.searches[area] ?? []).concat(this.user.searches['secdocuments'] ?? [])
    } else {
      return this.user.searches[area]
    }
  }

  loadRecentSearch(text: string) {
    this.searchString = text
    this.doSearch()
  }

  saveSiteSearch() {
    this.savedSearchService.saveSearch({
      area: 'site-search',
      parameters: {
        term: this.searchString
      },
      type: 'search',
      name: 'Site Search: ' + this.searchString,
    })
  }


  openFeedbackOffCanvas(content: TemplateRef<any>) {
    this.feedbackForm.reason = ''
    this.feedbackForm.message = ''
    this.feedbackFormState = 'ready'
    this.offcanvasService.open(content, { position: 'end' })
  }

  clearToasts() {
    this.toastService.clear()
  }

  getQueryParams(area: string) {
    switch (area) {
      case 'news':
      case 'documents':
        return {
          content: this.searchString,
          case_include_type: "non-priority",
          _cb: Math.random()
        }
      case 'secdocuments':
        return {
          content: this.searchString,
        }
      case 'organizations':
      case 'people':
        return {
          _cb: Math.random(),
          name: '0:' + this.searchString
        }
      case 'situations':
        return {
          _cb: Math.random(),
          entity_name: '0:' + this.searchString
        }
    }
    return {}
  }

  loadSiteSearch(search: any) {
    this.searchString = search.parameters.term
    this.doSearch()
  }

  addRouteBuster(values: any) {
    if (values) {
      values = structuredClone(values)
      values._cb = Math.random()
    }
    return values
  }

  sendFeedback() {
    this.feedbackFormState = 'busy'
    this.searchService.sendFeedback(this.feedbackForm).subscribe((res) => {
      if (res.sent) {
        this.feedbackFormState = 'sent'
      } else {
        this.feedbackFormState = 'error'
      }
    })

    return false
  }
}