import { Component, OnInit, inject } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { AuthService } from 'src/app/services/auth.service'
import { validate as uuidValidate } from 'uuid'


@Component({
    selector: 'app-forgotten-password',
    templateUrl: './forgotten-password.component.html',
    styleUrls: ['./forgotten-password.component.scss'],
    standalone: true
})
export class ForgottenPasswordComponent implements OnInit {
  private router = inject(Router)
  private authService = inject(AuthService)


  public token: string
  public tokenValid: boolean
  public validatingToken: boolean = true

  constructor() {
    const route = inject(ActivatedRoute)

    this.token = route.snapshot.paramMap.get('token') ?? ''
    this.tokenValid = uuidValidate(this.token)
  }

  ngOnInit(): void {
    this.authService.validateTokenCookie(this.token).subscribe((response: any) => {
      if (response?.loggedIn) {
        this.router.navigateByUrl('/home')
      } else
        this.validatingToken = false
    })
  }
}
