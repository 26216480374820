<app-public-page-header></app-public-page-header>

<ng-scrollbar class="ngr-scrollbar" visibility="visible">
    @if ((user$ | async)?.CustId != '0') {
        <div class="container">
            <div class="row">
                <div class="col w-100">
                    <a href="/impersonate/{{token}}">
                    <div class="d-grid gap-2">
                            <button class="btn btn-primary" type="button">
                                Right click and choose "Open Link in Icognito Window" to impersonate the user.
                            </button>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    } @else if (urlValid) {
        @if (checkingImpersonationToken) {
        <div class="container">
            <div class="row">
                <div class="col">
                    <div class="m-4 progress-container">
                        <ngb-progressbar [value]="100" [striped]="true" [animated]="true"></ngb-progressbar>
                    </div>
                </div>
            </div>
        </div>
        } @else {
            <div class="container">
                <div class="row">
                    <div class="col message">
                        <div class="card mt-2">
                            <div class="card-header">
                                PROBLEM
                            </div>
                            <div class="card-body">
                                <div class="mb-3">
                                    <p>{{message}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
}
</ng-scrollbar>