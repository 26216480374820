import { Component, inject } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { NgbProgressbar } from '@ng-bootstrap/ng-bootstrap'
import { NgScrollbar } from 'ngx-scrollbar'
import { AuthService } from 'src/app/services/auth.service'
import { PasswordStrengthComponent } from 'src/app/shared/components/password-strength/password-strength.component'
import { PublicPageHeaderComponent } from 'src/app/shared/components/public-page-header/public-page-header.component'
import { User } from 'src/app/state/user.model'

@Component({
  selector: 'app-activate-user',
  standalone: true,
  imports: [
    PublicPageHeaderComponent,
    NgbProgressbar,
    NgScrollbar,
    FormsModule,
    PasswordStrengthComponent,
  ],
  templateUrl: './activate-user.component.html',
  styleUrl: './activate-user.component.scss'
})

export class ActivateUserComponent {
  public contactID: number
  public email: string
  public token: string = ''
  public urlValid = false

  public message = ''

  public checkingActivationLink = false
  public askingForPassword = false
  public settingInitialPassword = false
  public accountActivatedOk = false
  public strongPassword = false

  public newPassword = ''


  public user: User = {} as User

  private router = inject(Router)
  private route = inject(ActivatedRoute)
  private authService = inject(AuthService)

  constructor() {
    this.contactID = parseInt(this.route.snapshot.paramMap.get('contactID') ?? '')
    this.email = this.route.snapshot.paramMap.get('email') ?? ''

    this.urlValid = this.contactID > 0 && this.email.length > 0
  }

  ngOnInit(): void {
    if (this.urlValid) {
      this.checkingActivationLink = true
      this.authService.validateActivate(this.contactID, this.email).subscribe((response: any) => {
        this.checkingActivationLink = false
        if (response?.message === 'success') {
          this.askingForPassword = true
          this.token = response.token
        } else if (response?.message === 'activated') {
          this.message = 'This account has already been activated.'
        } else if (response?.message === 'invalid') {
          this.message = 'The activation link is incorrect &mdash; please check it.'
        }
      })
    } else {
      this.message = 'The activation link is incorrect &mdash; please check it.'
    }
  }

  activateAccount() {
    this.askingForPassword = false
    this.settingInitialPassword = true
    this.authService.activateAccount(this.contactID, this.token, this.newPassword).subscribe((result: any) => {
      this.settingInitialPassword = false
      this.accountActivatedOk = result.ok
      this.message = result.message
      if (this.accountActivatedOk) {
        setTimeout(() => {
          this.router.navigateByUrl('/home')
        }, 5000)
      }
    })
  }

  onPasswordStrengthChanged(event: boolean) {
    this.strongPassword = event;
  }
}