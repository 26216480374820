<app-public-page-header></app-public-page-header>


@if (loading) {
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="m-4 progress-container">
          <ngb-progressbar [value]="100" [striped]="true" [animated]="true"></ngb-progressbar>
        </div>
      </div>
    </div>
  </div>
} @else {
  <div class="container py-5 h-100">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col">
        <div class="card rounded-3 text-black">
          <div class="card-body p-md-5 mx-md-4">
            @if (pageMode == 'unsubscribe') {
              <form (ngSubmit)="onSubmit()" ngNativeValidate class="needs-validation">
                <h2 class="text-center bd-green-colour">Unsubscribe from "{{title}}"?</h2>
                <div class="text-center pt-1 mb-5 pb-1">
                  <div class="d-grid gap-2">
                    @if (isWaiting) {
                      <button class="btn btn-bankruptcydata btn-block mb-3" type="submit">
                        Unsubscribe
                      </button>
                    } @else {
                      <button class="btn btn-bankruptcydata btn-block mb-3" type="button" disabled>
                        <span class="spinner-border spinner-border-sm" role="status"></span>
                      </button>
                    }
                  </div>
                </div>
              </form>
            }
            @if (pageMode == 'messaging') {
              <h2 class="text-center bd-green-colour" [innerHTML]="message"></h2>
            }
          </div>
        </div>
      </div>
    </div>
  </div>
}
