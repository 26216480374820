<app-public-page-header></app-public-page-header>

<ng-scrollbar class="ngr-scrollbar" visibility="visible">
    @if (urlValid) {

        @if (checkingActivationLink) {
        <div class="container">
            <div class="row">
                <div class="col">
                    <div class="m-4 progress-container">
                        <ngb-progressbar [value]="100" [striped]="true" [animated]="true"></ngb-progressbar>
                    </div>
                </div>
            </div>
        </div>
        } @else {

        <div class="container">
            <div class="row">
                <div class="col message">
                    <div class="card mt-2">
                        @if (settingInitialPassword) {
                        <div class="card-overlay">
                            <div class="spinner-border">

                            </div>
                        </div>
                        } @else if (askingForPassword) {
                            <div class="card-header">
                                Activate
                            </div>
                            <div class="card-body">
                                <div>
                                    <label for="email" class="form-label">Email Address</label>
                                    <input type="text" class="form-control" id="email" [value]="email" readonly>
                                </div>
                                <div class="mb-3">
                                    <label for="newPassword1" class="form-label">New Password</label>
                                    <input type="password" class="form-control" id="newPassword1" [(ngModel)]="newPassword">
                                    <app-password-strength [passwordToCheck]="newPassword" (passwordStrength)="onPasswordStrengthChanged($event)"></app-password-strength>
                                </div>
                                <div class="mb-3">
                                </div>
                                <button type="submit" class="btn btn-gradient" (click)="activateAccount()" [disabled]="!strongPassword">Submit</button>
                            </div>
                        } @else {
                            @if (accountActivatedOk) {
                                <div class="card-header">
                                    Password Changed
                                </div>
                                <div class="card-body">
                                    <div class="mb-3">
                                        <p>Your account has been activated. Click <a href="/home">here</a> to continue.</p>
                                    </div>
                                </div>
                            } @else {
                                <div class="card-header">
                                    {{message}}
                                </div>
                                <div class="card-body">
                                    <div class="mb-3">
                                        <p>Please try logging into the site <a href="/home">here</a>.</p>
                                    </div>
                                </div>
                            }
                        }

                    </div>
                </div>
            </div>
        </div>
        }
    }
</ng-scrollbar>